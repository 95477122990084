import React from "react";
import { images } from "../../constants";

import "./AboutUs.css";

const AboutUs = () => (
  <div
    className="app_aboutus app__bg flex__center section__padding "
    id="about"
  >
    {/* <div className="app__aboutus-overlay flex__center">
            <img src={images.fc} alt="g letter"/>
        </div> */}

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Om French Chicken</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans-about">
          {" "}
          Velkommen til French Chicken, hvor vores kyllinger møder dansk
          hygge. Vi byder på et udsøgt udvalg af retter fra velsmagende
          sandwiches og kyllinger til et varieret kartoffelselektion samt
          traditionel flæskesteg og frikadeller. Oplev den perfekte version
          af moderne take-away med os, og nyd et måltid, hvor
          tradition og komfort mødes{" "}
        </p>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.line} alt="about_knife"className="line__img" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Historie</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img-reverse" />
        <p className="p__opensans-about">
        Vi har siden 2005 været beliggende på Kongelundsvej 260, 
        hvor vi har tilbudt kvalitetskyllingretter og andre delikatesser med et dedikeret fokus på autenticitet og kvalitet. 
        For os har det altid været vigtigt at kunne tilbyde vores kunder et udvalg af lækre retter,
         der kan leve op til deres forventninger. 
         Vi glæder os til at dele vores lækre måltider med jer.{" "}
        </p>
      </div>
    </div>
  </div>
);

export default AboutUs;
