import React, { useState, useEffect } from 'react';
import './Header.css';
import { images } from '../../constants';
import { SubHeading } from '../../components';

const Header = ({ scrollToMenu }) => {
  const [buttonText, setButtonText] = useState('Udforsk menuen');

  useEffect(() => {
    const updateButtonText = () => {
      setButtonText(window.innerWidth > 650 ? 'Udforsk menuen' : 'Bestil her');
    };

    // Update the button text on initial render
    updateButtonText();

    // Update the button text whenever the window is resized
    window.addEventListener('resize', updateButtonText);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateButtonText);
    };
  }, []);

  const handleButtonClick = () => {
    if (window.innerWidth > 650) {
      // For larger screens, scroll to menu
      scrollToMenu();
    } else {
      // For mobile screens, redirect to order website
      window.location.href = 'https://www.eatonline.dk/restaurant/751/french-chicken-kastrup';
    }
  };

  return (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
        <SubHeading title="French Chicken Kastrup" />
        <h1 className="app__header-h1"> Smag rejsen i hvert bid! </h1>
        <br />
        <button type="button" className="custom__button" onClick={handleButtonClick}>
          {buttonText}
        </button>
      </div>

      <div className="app__wrapper_img">
        <img src={images.chicken} alt="header img" />
      </div>
    </div>
  );
};

export default Header;
