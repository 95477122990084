import React, { createRef, useState, useEffect } from 'react';
import { AboutUs, FindUs, Header, Intro, SpecialMenu } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => {
    const menuRef = createRef();
    const [popupData, setPopupData] = useState(null); // Popup data includes message, title, and button text
    const [isLoading, setIsLoading] = useState(true); // State to track loading

    const scrollToMenu = () => {
        menuRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const isValidMessage = (timestamp, duration) => {
        const currentTime = Date.now();
        return currentTime - timestamp <= duration * 1000;
    };

    useEffect(() => {
        // Fetch the admin message from the JSON file
        const fetchMessage = async () => {
            setIsLoading(true); // Start loading
            try {
                const response = await fetch('https://frenchchicken.netlify.app/.netlify/functions/fetch-message');
                if (!response.ok) throw new Error('Failed to fetch message');

                const data = await response.json();
                const { title, message, buttonText, timestamp, duration } = data;

                // Check if the message is valid
                if (isValidMessage(timestamp, duration)) {
                    setPopupData({ title, message, buttonText });
                }
            } catch (error) {
                console.error('Error fetching admin message:', error);
            } finally {
                setIsLoading(false); // End loading
            }
        };

        fetchMessage();
    }, []);

    useEffect(() => {
        // Handle auto-dismiss logic when popupData changes
        if (popupData) {
            const timeout = setTimeout(() => {
                setPopupData(null); // Automatically dismiss the popup
            }, 6000); // Default 6 seconds

            // Cleanup the timeout if popupData changes or component unmounts
            return () => clearTimeout(timeout);
        }
    }, [popupData]);

    return (
        <div>
            <Navbar />
            <Header scrollToMenu={scrollToMenu} />
            <SpecialMenu ref={menuRef} />
            <Intro />
            <AboutUs />
            <FindUs />
            {/* Show loading spinner if the data is still loading */}
            {isLoading && <p className="loading">Loading...</p>}
            {/* Render the popup if there's a valid message */}
            {!isLoading && popupData && (
                <div className="popup">
                    <div className="popup-content">
                        <h2>{popupData.title || 'Notification'}</h2>
                        <p>{popupData.message}</p>
                        <button onClick={() => setPopupData(null)}>
                            {popupData.buttonText || 'Close'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;