import React, { useState, useRef } from 'react';
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';

import './Intro.css';

const Intro = () => {
    const [playVideo, setPlayVideo] = useState(false); 
    const vidRef = useRef();

    const handleVideo = () => {
        setPlayVideo((prevPlayVideo) => {
            if (prevPlayVideo) {
                vidRef.current.pause();
            } else {
                vidRef.current.play();
            }
            return !prevPlayVideo;
        });
    };

    return (
        <div className="app__video">
            <video
              src={`${process.env.PUBLIC_URL}/meal.mp4`}
                ref={vidRef}
                type="video/mp4"
                loop
                controls={false}
                muted
            >
                Your browser does not support the video tag.
            </video>
            <div className="app__video-overlay flex__center">
                <div
                    className="app__video-overlay_circle flex__center"
                    onClick={handleVideo}
                >
                    {playVideo
                        ? <BsPauseFill color="#fff" fontSize={30} />
                        : <BsFillPlayFill color="#fff" fontSize={30} />
                    }
                </div>
            </div>
        </div>
    );
};

export default Intro;